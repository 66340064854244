import { render, staticRenderFns } from "./GameInfoManagement.vue?vue&type=template&id=33e63844&scoped=true&"
import script from "./GameInfoManagement.vue?vue&type=script&lang=js&"
export * from "./GameInfoManagement.vue?vue&type=script&lang=js&"
import style0 from "./GameInfoManagement.vue?vue&type=style&index=0&id=33e63844&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33e63844",
  null
  
)

export default component.exports