<template>
    <div class="tournament-participant">
        <div class="body">
            <div class="block-place">
                <div v-if="position == 1" class="gold">
                    <p>{{position}}st</p>

                    <div class="block-prize">
                        <span>{{ item.prize % 1 !== 0 ? item.prize.toFixed(2) : item.prize }}</span>
                        <img v-if="tournament.wallet == 1" src="/dist/images/frontend/money.svg" alt />
                        <img v-else src="/dist/images/frontend/light-coin.svg" alt />
                    </div>
                </div>

                <div v-else-if="position == 2" class="silver">
                    <p>{{position}}nd</p>

                    <div class="block-prize">
                        <span>{{ item.prize % 1 !== 0 ? item.prize.toFixed(2) : item.prize }}</span>
                        <img v-if="tournament.wallet == 1" src="/dist/images/frontend/money.svg" alt />
                        <img v-else src="/dist/images/frontend/light-coin.svg" alt />
                    </div>
                </div>

                <div v-else-if="position == 3" class="bronze">
                    <p>{{position}}rd</p>

                    <div class="block-prize">
                        <span>{{ item.prize % 1 !== 0 ? item.prize.toFixed(2) : item.prize }}</span>
                        <img v-if="tournament.wallet == 1" src="/dist/images/frontend/money.svg" alt />
                        <img v-else src="/dist/images/frontend/light-coin.svg" alt />
                    </div>
                </div>

                <div v-else class="place" :style="(item.prize) ? {border: '2px solid #000'} : ''">
                    <p :style="(item.ban) ? {backgroundColor: '#D32030'} : {backgroundColor: '#000000'}">{{position}}</p>

                    <div class="block-prize">
                        <span>{{ item.prize % 1 !== 0 ? item.prize.toFixed(2) : item.prize }}</span>
                        <img v-if="tournament.wallet == 1" src="/dist/images/frontend/money.svg" alt />
                        <img v-else src="/dist/images/frontend/light-coin.svg" alt />
                    </div>
                </div>
            </div>

            <div v-if="item.user" class="block-player">
                <router-link :to="{name: 'profile', params: {id: item.user.public_id, lang: $store.getters.getLang}}">
                    <div class="wrap-img" :class="(item.user.pro) ? 'user-pro-img' : ''">
                        <img class="img" v-if="item.user.photo && typeof item.user.photo == 'object'" :src="item.user.photo[40]">
                        <img class="img"  v-else-if="item.user.photo" :src="item.user.photo">
                        <span v-else class="photo" :style="{'background': item.user.bg_color}">{{item.user.name.substr(0,1)}}</span>
                        <div v-if="now_date < date" class="online"></div>
                    </div>
                </router-link>

                <div class="player-info">
                    <router-link class="block-name" :to="{name: 'profile', params: {id: item.user.public_id, lang: $store.getters.getLang}}" :class="(item.user.pro) ? 'user-pro' : ''">
                        {{item.user.name+" "+((item.user.last_name) ? item.user.last_name : '')}}
                    </router-link>

                    <div class="block-info">
                        <div class="rating">
                            <img  src="/dist/images/frontend/fire.svg" alt />
                            <p class="stat">{{item.user.rating_points}}</p>
                        </div>
                        <div class="location">
                            <img :src="item.user.location_img">
                            <p>{{$getLocation(item.user.location)}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class="block-game">
                <div v-if="typeof tournament.game.logo == 'object'" class="block-game__img" :style="{'backgroundImage': 'url(' +tournament.game.logo[300] + ')'}"></div>
                <div v-else class="block-game__img" :style="{'backgroundImage': 'url(' +tournament.game.logo + ')'}"></div>

                <div class="block-game__info">
                    <p class="block-name">{{gameTranslation(tournament.game.game_translations).name}}</p>

                    <div class="block-attr" v-if="tournament.attribute && tournament.attribute.length">
                        <p v-for="(item, key) in tournament.attribute" :key="key">{{item.name}}: <span>{{item.value}}</span></p>
                    </div>
                </div>
            </div>

            <div class="block-stat">
                <p>{{item.count_game}}/{{tournament.count_match}}</p>
            </div>

            <div class="block-result">
                <p>{{item.total_point}}</p>
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.58901 1.00149L7.58941 1.00229L9.14794 4.13428L9.14793 4.13429L9.14945 4.13728C9.33752 4.50895 9.69631 4.76783 10.1117 4.82604C10.1124 4.82613 10.113 4.82622 10.1137 4.82631L13.611 5.33569L13.6135 5.33604C13.6886 5.34658 13.7557 5.38606 13.8006 5.44497L13.8008 5.44528C13.843 5.50062 13.861 5.57068 13.8508 5.63928L13.8506 5.64071C13.842 5.69987 13.8142 5.75522 13.7711 5.79796C13.7708 5.79827 13.7705 5.79858 13.7702 5.79889L11.2396 8.25187C10.931 8.54037 10.7928 8.96464 10.8672 9.37755L10.8672 9.37775L11.4906 12.8284C11.512 12.9751 11.4165 13.112 11.2728 13.1433C11.2115 13.1516 11.1468 13.141 11.0879 13.1114L7.96771 11.4858L7.96773 11.4857L7.96196 11.4828C7.58854 11.2944 7.14757 11.2944 6.77415 11.4828L6.77413 11.4828L6.7684 11.4858L3.64573 13.1127L3.64572 13.1127L3.6422 13.1146C3.49996 13.1901 3.32517 13.1407 3.24335 13.0038C3.21778 12.9519 3.20825 12.8931 3.21633 12.8347L3.83947 9.38479C3.83947 9.38478 3.83948 9.38476 3.83948 9.38474C3.91411 8.97171 3.77446 8.54634 3.46726 8.25837L0.93396 5.80406L0.93334 5.80347C0.830451 5.70413 0.82717 5.54235 0.92356 5.43952L0.939648 5.42343L0.939662 5.42344L0.942698 5.42035C0.983467 5.37886 1.03674 5.35268 1.09457 5.34581L1.09459 5.34598L1.1077 5.34407L4.60645 4.8338C4.6068 4.83375 4.60715 4.8337 4.60749 4.83365C5.02041 4.77479 5.37978 4.51856 5.56916 4.14427L5.56927 4.14433L5.57402 4.13441L7.07451 0.999656C7.12223 0.906091 7.21953 0.847606 7.32597 0.850082L7.32597 0.850218H7.3376H7.3937C7.47713 0.867278 7.54983 0.922403 7.58901 1.00149Z" stroke="#01DF72"/>
                </svg>
            </div>


<!--            <div v-if="item.user" class="block-btn">-->
<!--                <BaseButton v-if="auth && item.user.id == auth.id" @click="edit_match = true" :loading="submitDisabledEdit">-->
<!--                    <span>-->
<!--                        <img src="/dist/images/frontend/icon-edit.svg" alt />-->
<!--                        <p>{{__('Edit')}}</p>-->
<!--                    </span>-->
<!--                </BaseButton>-->

<!--                <v-tooltip-->
<!--                   v-else-if="auth && item.count_game_user >= tournament.count_match"-->
<!--                   bottom-->
<!--                   color="transparent"-->
<!--                   nudge-top="8"-->
<!--                >-->
<!--                    <template v-slot:activator="{ on, attrs }">-->
<!--                        <div-->
<!--                            v-on="on"-->
<!--                            v-bind="attrs"-->
<!--                            class="btn-result"-->
<!--                        >-->
<!--                            <p>{{item.user_result}}</p>-->
<!--                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                                <path d="M8.08901 1.50149L8.08941 1.50228L9.64794 4.63427L9.64793 4.63428L9.64945 4.63728C9.83752 5.00894 10.1963 5.26782 10.6117 5.32603C10.6124 5.32612 10.613 5.32621 10.6137 5.3263L14.111 5.83568L14.1135 5.83603C14.1886 5.84657 14.2557 5.88606 14.3006 5.94496L14.3008 5.94527C14.343 6.00061 14.361 6.07067 14.3508 6.13927L14.3506 6.1407C14.342 6.19987 14.3142 6.25521 14.2711 6.29796C14.2708 6.29826 14.2705 6.29857 14.2702 6.29888L11.7396 8.75186C11.431 9.04036 11.2928 9.46463 11.3672 9.87754L11.3672 9.87775L11.9906 13.3283C12.012 13.4751 11.9165 13.612 11.7728 13.6433C11.7115 13.6516 11.6468 13.641 11.5879 13.6114L8.46771 11.9858L8.46773 11.9857L8.46196 11.9828C8.08854 11.7944 7.64757 11.7944 7.27415 11.9828L7.27413 11.9828L7.2684 11.9858L4.14573 13.6127L4.14572 13.6127L4.1422 13.6146C3.99996 13.6901 3.82517 13.6407 3.74335 13.5038C3.71778 13.4519 3.70825 13.3931 3.71633 13.3347L4.33947 9.88479C4.33947 9.88477 4.33948 9.88475 4.33948 9.88473C4.41411 9.4717 4.27446 9.04633 3.96726 8.75837L1.43396 6.30406L1.43334 6.30346C1.33045 6.20412 1.32717 6.04234 1.42356 5.93951L1.43965 5.92342L1.43966 5.92343L1.4427 5.92034C1.48347 5.87885 1.53674 5.85268 1.59457 5.8458L1.59459 5.84598L1.6077 5.84406L5.10645 5.33379C5.1068 5.33374 5.10715 5.33369 5.10749 5.33364C5.52041 5.27479 5.87978 5.01855 6.06916 4.64426L6.06927 4.64432L6.07402 4.6344L7.57451 1.49965C7.62223 1.40608 7.71953 1.3476 7.82597 1.35007L7.82597 1.35021H7.8376H7.8937C7.97713 1.36727 8.04983 1.4224 8.08901 1.50149Z" stroke="#6886AF"/>-->
<!--                            </svg>-->
<!--                        </div>-->
<!--                    </template>-->
<!--                    <p class="block-tooltip-info ma-0">-->
<!--                        {{__('Cumulative result of matches played')}}-->
<!--                    </p>-->
<!--                </v-tooltip>-->


<!--                <BaseButton-->
<!--                    v-else-if="auth && tournament.participate && !item.user.plays && now_date < date && now_date > date_start && now_date < date_finish && item.ban < 2"-->
<!--                    :loading="submitDisabled"-->
<!--                    @click="modal_confirm_invite = true"-->
<!--                >-->
<!--                    <span>-->
<!--                        <img src="/dist/images/frontend/create-btn.svg" alt="/" />-->
<!--                        <p>{{__('Play')}} {{item.count_game_user}}/{{tournament.count_match}}</p>-->
<!--                    </span>-->

<!--                    <div :style="{width: ((item.count_game_user * 100) / tournament.count_match) + '%'}" class="match-progress"></div>-->
<!--                </BaseButton>-->

<!--                <div v-else-if="item.user.plays" class="btn-noactive played">-->
<!--&lt;!&ndash;                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">&ndash;&gt;-->
<!--&lt;!&ndash;                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3197 10.9415C13.3466 12.8111 7.97672 15.9747 5.22953 16.9904C4.79106 17.1521 3.67323 17.4947 3.43234 17.4994C2.9208 17.5137 2.4282 17.2616 2.19002 16.8572C2.09799 16.7002 1.82733 15.675 1.74072 15.2183C1.48359 13.8292 1.35097 11.6717 1.35368 9.50241C1.35097 7.22605 1.49442 4.96633 1.78132 3.59148C1.85711 3.22041 2.07905 2.36647 2.144 2.22851C2.2658 1.97162 2.48774 1.76943 2.7557 1.64336C2.9614 1.54584 3.19687 1.49351 3.43234 1.50065C3.67323 1.5054 4.65302 1.80749 4.98864 1.92404C7.63569 2.82793 13.2356 6.10571 15.2927 8.03241C15.4767 8.20605 15.9828 8.67227 16.0667 8.76741C16.2589 8.98149 16.3536 9.24076 16.3536 9.50241C16.3536 9.74504 16.2697 9.99241 16.0965 10.1994C16.0072 10.3064 15.4902 10.7821 15.3197 10.9415Z" stroke="#6886AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>&ndash;&gt;-->
<!--&lt;!&ndash;                    </svg>&ndash;&gt;-->

<!--                    <p>{{__('Now playing')}}</p>-->
<!--                </div>-->

<!--                <div v-else class="btn-noactive">-->
<!--                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3197 10.9415C13.3466 12.8111 7.97672 15.9747 5.22953 16.9904C4.79106 17.1521 3.67323 17.4947 3.43234 17.4994C2.9208 17.5137 2.4282 17.2616 2.19002 16.8572C2.09799 16.7002 1.82733 15.675 1.74072 15.2183C1.48359 13.8292 1.35097 11.6717 1.35368 9.50241C1.35097 7.22605 1.49442 4.96633 1.78132 3.59148C1.85711 3.22041 2.07905 2.36647 2.144 2.22851C2.2658 1.97162 2.48774 1.76943 2.7557 1.64336C2.9614 1.54584 3.19687 1.49351 3.43234 1.50065C3.67323 1.5054 4.65302 1.80749 4.98864 1.92404C7.63569 2.82793 13.2356 6.10571 15.2927 8.03241C15.4767 8.20605 15.9828 8.67227 16.0667 8.76741C16.2589 8.98149 16.3536 9.24076 16.3536 9.50241C16.3536 9.74504 16.2697 9.99241 16.0965 10.1994C16.0072 10.3064 15.4902 10.7821 15.3197 10.9415Z" stroke="#6886AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                    </svg>-->

<!--                    <p>{{__('Play')}} {{item.count_game_user}}/{{tournament.count_match}}</p>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div v-else class="block-btn">-->
<!--                <BaseButton-->
<!--                    :to="{name: 'tournament', params: {'id': tournament.id, lang: $store.getters.getLang}}"-->
<!--                >-->
<!--                    <span>-->
<!--                        <p>{{__('See tournament')}}</p>-->
<!--                    </span>-->
<!--                </BaseButton>-->
<!--            </div>-->
        </div>

        <div class="participant-info">
            <div class="item-info">
                <span>Сыграно игр:</span>
                <p>{{item.count_game}}/{{tournament.count_match}}</p>
            </div>
            <div class="item-info">
                <span>{{__('Result')}}:</span>
                <p>{{item.total_point}}</p>
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.58901 1.00149L7.58941 1.00229L9.14794 4.13428L9.14793 4.13429L9.14945 4.13728C9.33752 4.50895 9.69631 4.76783 10.1117 4.82604C10.1124 4.82613 10.113 4.82622 10.1137 4.82631L13.611 5.33569L13.6135 5.33604C13.6886 5.34658 13.7557 5.38606 13.8006 5.44497L13.8008 5.44528C13.843 5.50062 13.861 5.57068 13.8508 5.63928L13.8506 5.64071C13.842 5.69987 13.8142 5.75522 13.7711 5.79796C13.7708 5.79827 13.7705 5.79858 13.7702 5.79889L11.2396 8.25187C10.931 8.54037 10.7928 8.96464 10.8672 9.37755L10.8672 9.37775L11.4906 12.8284C11.512 12.9751 11.4165 13.112 11.2728 13.1433C11.2115 13.1516 11.1468 13.141 11.0879 13.1114L7.96771 11.4858L7.96773 11.4857L7.96196 11.4828C7.58854 11.2944 7.14757 11.2944 6.77415 11.4828L6.77413 11.4828L6.7684 11.4858L3.64573 13.1127L3.64572 13.1127L3.6422 13.1146C3.49996 13.1901 3.32517 13.1407 3.24335 13.0038C3.21778 12.9519 3.20825 12.8931 3.21633 12.8347L3.83947 9.38479C3.83947 9.38478 3.83948 9.38476 3.83948 9.38474C3.91411 8.97171 3.77446 8.54634 3.46726 8.25837L0.93396 5.80406L0.93334 5.80347C0.830451 5.70413 0.82717 5.54235 0.92356 5.43952L0.939648 5.42343L0.939662 5.42344L0.942698 5.42035C0.983467 5.37886 1.03674 5.35268 1.09457 5.34581L1.09459 5.34598L1.1077 5.34407L4.60645 4.8338C4.6068 4.83375 4.60715 4.8337 4.60749 4.83365C5.02041 4.77479 5.37978 4.51856 5.56916 4.14427L5.56927 4.14433L5.57402 4.13441L7.07451 0.999656C7.12223 0.906091 7.21953 0.847606 7.32597 0.850082L7.32597 0.850218H7.3376H7.3937C7.47713 0.867278 7.54983 0.922403 7.58901 1.00149Z" stroke="#01DF72"/>
                </svg>
            </div>
        </div>


        <div class="block-progress">
            <div v-if="item.ban" class="body-failed">
                <progress style="width: 100%" :value="item.count_game" :max="(Number(item.count_game) * Number(tournament.count_match)) - Number(tournament.count_match)"></progress>
            </div>
            <div v-else>
                <progress style="width: 100%" :value="item.count_game" :max="(Number(item.count_game) * Number(tournament.count_match)) - Number(tournament.count_match)"></progress>
            </div>
        </div>

        <ModalDefault v-if="modal_confirm_invite" v-bind:modal_confirm_invite.sync="modal_confirm_invite" :width="400">
            <div class="modal-invite">
                <div class="modal-invite__head">
                    <v-list-item class="pa-0">
                        <div class="wrap-img">
                            <img v-if="typeof tournament.game.logo == 'object'" :src="tournament.game.logo[100]" />
                            <img v-else :src="tournament.game.logo" />
                        </div>
                        <v-list-item-content>
                            <div>
                                <v-list-item-title>{{__('Send Invite')}}</v-list-item-title>
                                <v-list-item-subtitle>{{gameTranslation(tournament.game.game_translations).name}}</v-list-item-subtitle>
                            </div>
                        </v-list-item-content>
                    </v-list-item>

                    <div class="wrap-slide">
                        <v-slider
                            v-model="value"
                            :max="item.match.amount_to"
                            :min="item.match.amount_from"
                            step="0.1"
                        >
                        </v-slider>
                    </div>
                </div>
                <div class="modal-invite__body">
                    <div>
                        <div class="wrapper-btn" v-if="item.match.wallet == 1">
                            <v-btn text v-ripple="false" @click="value = item.match.amount_from" class="real-btn">
                                <img height="16" src="/dist/images/frontend/money.svg" alt="">

                                <span style="color: #FDAE02">{{item.match.amount_from}}</span>
                            </v-btn>

                            <v-btn text v-ripple="false" @click="value = Number(Math.floor(item.match.amount_to * 10) / 10)" class="real-btn" >
                                <img height="16" src="/dist/images/frontend/money.svg" alt="">

                                <span style="color: #FDAE02">{{Number(Math.floor(item.match.amount_to * 10) / 10)}}</span>
                            </v-btn>
                        </div>


                        <div class="wrapper-btn" v-else>
                            <v-btn text v-ripple="false"  @click="value = item.match.amount_from" class="lite-btn">
                                <img height="16" src="/dist/images/frontend/light-coin.svg" alt="">
                                <span style="color: #607EA7">{{item.match.amount_from}}</span>
                            </v-btn>

                            <v-btn text v-ripple="false"  @click="value = Number(Math.floor(item.match.amount_to * 10) / 10)"  class="lite-btn">
                                <img height="16" src="/dist/images/frontend/light-coin.svg" alt="">

                                <span style="color: #607EA7">{{Number(Math.floor(item.match.amount_to * 10) / 10)}}</span>
                            </v-btn>
                        </div>
                    </div>
                    <div class="wrap-price">
                        <p class="wrap-price__title">{{__('Enter amount')}} ({{item.match.amount_from + ' - ' + item.match.amount_to}})</p>

                        <div class="wrap-price__sum">
                            <InputLabelTextDefault
                                posLabel="right"
                                type="number"
                                min="1"
                                color="border-norm"
                                placeholder="0"
                                v-model="amount"
                                v-bind:errors="formValidate.amount"
                                @change="updateValue"
                                @input="updateValidate('amount')"
                            >
                                <template v-slot:right>
                                    <div class="currency">
                                        <span v-if="item.match.wallet== 1">
                                          <img width="20px" src="/dist/images/frontend/money.svg" alt="">
                                        </span>

                                        <span v-else-if="item.match.wallet== 2">
                                           <img width="20px" src="/dist/images/frontend/light-coin.svg" alt />
                                        </span>
                                    </div>
                                </template>
                            </InputLabelTextDefault>
                        </div>
                    </div>

                    <div v-if="item.match.password" class="wrap-price mt-5">
                        <p class="wrap-price__title">{{__('Game password')}}</p>
                        <InputLabelTextDefault
                            type="password"
                            switchType
                            color="border-norm"
                            v-model="password"
                            v-bind:errors="formValidate.password"
                            @input="updateValidate('password')"
                        >
                        </InputLabelTextDefault>
                    </div>

                    <div class="block-params" v-if="tournament.attribute && tournament.attribute.length">
                        <div class="block-params__head">
                            {{__('game options')}}
                        </div>

                        <div class="block-params__body">
                            <div v-for="(item, key) in tournament.attribute" :key="key" class="item-params">
                                <span>{{item.name}}</span>
                                <p>{{item.value}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="wrap-btn">
                        <BaseButton
                            class="mr-4"
                            class_btn="btn-liteGrey-quest"
                            v-on:click="modal_confirm_invite = false"
                            width="120"
                        >
                            <span>{{__('Cancel')}}</span>
                        </BaseButton>

                        <BaseButton
                            width="120"
                            class_btn="btn-guest"
                            v-on:click="sendInvitation(((item.user) ? item.user.id : item.user_id), item.match_id)"
                            :loading="submitDisabled"
                        >
                            <span>{{__('Send Invite')}}</span>
                        </BaseButton>
                    </div>
                </div>
            </div>
        </ModalDefault>

        <ModalEditMatch
            v-if="edit_match"
            :edit_match.sync="edit_match"
            :tournament="tournament"
            :item="item"
            :submitDisabledEdit.sync="submitDisabledEdit"
            @change_users="changeUsers($event)"
        />

    </div>
</template>

<script>
    import moment from "moment";
    import ModalDefault from "../../modules/ModalDefault";
    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import {decimal, maxLength, maxValue, minLength, minValue, required} from "vuelidate/lib/validators";
    import ModalEditMatch from "./ModalEditMatch";

    export default {
        name: 'ItemParticipantSolo',
        data() {
            return {
                submitDisabled: false,
                now_date: moment.utc().format('X'),
                date: (this.item.user && this.item.user.online) ? moment.utc(this.item.user.online).format('X') : null,
                date_start: (this.tournament.date_start) ? moment.utc(this.tournament.date_start).format('X') : null,
                date_finish: (this.tournament.date_finish) ? moment.utc(this.tournament.date_finish).format('X') : null,

                modal_confirm_invite: false,
                amount: (this.item.match && this.item.match.amount_from) ? this.item.match.amount_from : null,
                value: (this.item.match && this.item.match.amount_from) ? this.item.match.amount_from : 0,
                password: null,
                formValidate: {
                    amount: null,
                    password: null,
                },
                edit_match: false,
                submitDisabledEdit: false,
            }
        },
        validations() {
            return {
                amount: {
                    decimal,
                    required,
                    minValue: minValue(this.item.match.amount_from),
                    maxValue: maxValue(this.item.match.amount_to),
                },
                password: {
                    required: (this.item.match.password) ? required : true,
                    minLength: minLength(4),
                    maxLength: maxLength(20),
                },
            }
        },
        watch: {
            value(val) {
                this.amount = val;
            }
        },
        props: {
            item: {
                type: Object
            },
            position: {
                type: [Number, String]
            },
            tournament: {
                type: Object
            }
        },
        computed:{
            auth() {
                return this.$store.state.auth.user;
            },
            lang() {
                return this.$store.getters.getLanguage;
            }
        },
        methods: {
            changeUsers(res) {
                this.$emit('change_users',res )
            },
            updateValidate: function (type) {
                this.formValidate = this.$changeValidate(
                    this.formValidate,
                    this.$v[type],
                    type
                );
            },
            updateValue() {
                this.value = this.amount;
            },
            gameTranslation(arr) {
                if (this.$filterArray(arr, this.lang, 'lang')) {
                    return this.$filterArray(arr, this.lang, 'lang');
                } else {
                    return this.$filterArray(arr, 'en', 'lang');
                }
            },
            pushRegister() {
                this.$store.commit('updateModalAuth', 'register');

            },
            sendInvitation(userId, match_id) {
                let matches = this.item.match;

                let valid = this.$v;

                if (valid.$invalid) {
                    let massages = {
                        amount: [],
                        password: []
                    };
                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    const formData = {
                        match_id: match_id,
                        game_id: this.tournament.game.id,
                        user_id: userId,
                        amount: this.amount,
                        wallet: matches.wallet,
                        password: this.password,
                    };

                    this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/games/play_on', formData)
                        .then(response=> {
                            return response.json()
                        })
                        .then((response)=> {
                            if(this.$checkAuth(response)) {

                                this.modal_confirm_invite = false;
                                response = response.data;

                                this.modal_confirm_invite = false;
                                this.amount =  matches.amount_from ? matches.amount_from : null,
                                this.value = matches.amount_from ? matches.amount_from : 0,

                                matches.timestamp = response.timestamp;
                                matches.cancel = false;

                                this.$store.dispatch("asyncModalInvite", matches);
                                this.$emit('data_invite', response);


                                if (this.$gtag) {
                                    this.$gtag.event('PlayGameDone', { 'GameID': this.game.id });
                                }
                                if (this.$metrika) {
                                    this.$metrika.reachGoal('PlayGameDone');
                                }
                            }
                        })
                        .catch((err) => {
                            this.httpHandler(err);
                        });

                    this.submitDisabled=true;
                    setTimeout(()=> {
                        this.submitDisabled= false;
                    }, 15000);
                }
            },
        },
        components: {
            ModalDefault,
            InputLabelTextDefault,
            ModalEditMatch
        }
    }
</script>

<style lang="scss" scoped>

    .block-tooltip-info {
        display: block;
        text-align: center;
        text-overflow: ellipsis;
        z-index: 1;
        font-size: 13px;
        line-height: 18px;
        color: #002B50;
        padding: 4px 8px;
        background: rgb(255, 255, 255);
        border: 1px solid #ECF4FF;
        border-radius: 6px;
        max-width: 250px;
        overflow: hidden;
    }

    .dark {
        .modal-invite {
            border-radius: 4px;
            background: #171827;
            &__head {
                background-color: #171827;

                .v-list-item {
                    &__title {
                        color: #fff;
                    }

                    &__subtitle {
                        color: #96AFD1;
                    }
                }
            }

            &__body {
                .wrap-price {
                    &__title {
                        color: #96AFD1;
                    }
                }

                .block-params {
                    &__head {
                        color: #fff;
                        background: #171827;
                    }

                    &__body {
                        .item-params {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

    .modal-invite {
        border-radius: 4px;
        &__head {
            /*display: flex;*/
            /*justify-content: space-between;*/
            /*align-items: center;*/
            background-color: #fff;
            border-radius: 8px 8px 0px 0px;
            padding: 10px 10px 0;
            position: relative;

            .wrap-slide {
                margin:0 2px 0;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    left: -12px;
                    top: 15px;
                    width: 20px;
                    height: 2px;
                    background-color: #E0ECFD;
                }

                &:before {
                    content: '';
                    position: absolute;
                    right: -12px;
                    top: 15px;
                    width: 20px;
                    height: 2px;
                    background-color: #E0ECFD;
                }


            }



            .v-list-item {
                display: flex;
                align-items: center;

                .wrap-img {
                    border-radius: 8px;
                    width: 40px;
                    height: 40px;
                    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);

                    img {
                        border-radius: 8px;
                        width: 40px;
                        height: 40px;
                    }
                }

                &__content {
                    padding: 0;
                    margin-left: 8px;
                }

                &__title {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 24px;
                    color: #000000;
                    margin-bottom: 1px;
                }

                &__subtitle {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    color: #002B50;

                    span {
                        color: #002B50;
                    }
                }
            }

        }

        &__body {
            padding: 10px 20px 20px;

            .wrapper-btn {
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }

            .v-btn.real-btn {
                height: 24px;
                background: #FFFFFF;
                border: 1px solid #FDAE02;
                box-shadow: 0 2px 5px rgba(51, 97, 255, 0.03);
                border-radius: 30px;
                padding: 0 5px;
                margin-right: 10px;
                font-size: 13px;
                line-height: 18px;
                transition: .3s;

                &:before {
                    background-color: #fff;
                }

                &:hover {
                    transform: scale(1.1);
                    transition: .3s;
                }

                img {
                    margin-right: 2px;
                }
            }

            .v-btn.lite-btn {
                height: 24px;
                background: #FFFFFF;
                border: 1px solid #96AFD1;
                box-shadow: 0 2px 5px rgba(51, 97, 255, 0.03);
                border-radius: 30px;
                padding: 0 5px;
                margin-right: 10px;
                font-size: 13px;
                line-height: 18px;
                transition: .3s;

                &:before {
                    background-color: #fff;
                }

                &:hover {
                    transform: scale(1.1);
                    transition: .3s;
                }
                img {
                    margin-right: 2px;
                }
            }

            .wrap-price {

                &__title {
                    font-size: 14px;
                    line-height: 12px;
                    display: flex;
                    align-items: center;
                    color: #6886AF;
                    opacity: 0.87;
                    margin-bottom: 10px;
                }
            }
            .currency {
                display: flex;
                align-items: center;

                img {
                    margin-bottom: 2px;
                }
            }

            .block-params {
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                width: 100%;
                cursor: default;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 9px;
                    width: 100%;
                    height: 1px;
                    background: linear-gradient(90deg, rgba(0, 174, 255, 0) 0%, rgba(0, 174, 255, 0.6) 36.16%, rgba(0, 174, 255, 0.6) 67.61%, rgba(0, 174, 255, 0) 100.62%);
                }


                &__head {
                    background: #fff;
                    padding: 1px 6px 1px;
                    border: 1px solid #00AEFF;
                    box-sizing: border-box;
                    border-radius: 4px;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 14px;
                    color: #002B50;
                    text-transform: uppercase;
                    margin-bottom: 10px;
                    position: relative;
                    z-index: 10;
                }

                &__body {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;

                    .item-params {
                        padding: 6px 12px;
                        display: flex;
                        align-items: center;
                        background: rgba(0, 174, 255, 0.1);
                        border-radius: 5px;
                        margin: 4px;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 19px;
                        display: flex;
                        align-items: center;
                        color: #002B50;

                        span {
                            margin-right: 6px;
                            text-transform: capitalize;
                        }

                        p {
                            margin: 0;
                            color: #00AEFF;
                        }
                    }
                }
            }

            .wrap-btn {
                margin-top: 20px;
                display: flex;
                justify-content: flex-end;

                div {
                    width: 50%;
                }
            }
        }
    }

    @media screen and (max-width: 450px) {
        .modal-invite {
            &__body {
                .block-params {
                    margin: 14px 0;

                    &__body {
                        .item-params {
                            padding: 4px 10px;
                            font-size: 12px;
                            line-height: 16px;
                        }
                    }
                }
            }
        }
    }
</style>