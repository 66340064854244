var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"flex","flex-shrink":"0"}},[(_vm.game.type == 1 || _vm.game.type == 3)?_c('div',{staticStyle:{"width":"100%"}},[(_vm.auth && _vm.game.list_auth && _vm.game.list_auth.length < 5)?_c('div',{staticClass:"wrap-create-btn"},[_c('BaseButton',{attrs:{"class_btn":"btn-green"},on:{"click":_vm.openModal}},[_c('span',[_vm._v(_vm._s(_vm.__('Create match')))])])],1):(_vm.auth)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('BaseButton',_vm._g(_vm._b({attrs:{"class_btn":"btn-liteGrey-game"}},'BaseButton',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.__('Create match')))])])]}}],null,false,579753307)},[_c('span',[_vm._v(_vm._s(_vm.__('Cant create more than 5 applications')))])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('BaseButton',_vm._g(_vm._b({attrs:{"class_btn":"btn-green"},on:{"click":_vm.pushRegister}},'BaseButton',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.__('Create match')))])])]}}],null,false,4099273164)},[_c('span',[_vm._v(_vm._s(_vm.__('You are not logged in')))])])],1):(_vm.game.type == 2 || _vm.game.type == 4)?_c('div',{staticStyle:{"width":"100%"}},[((_vm.game.type_user == 2 || _vm.game.type_user == 3) && _vm.game.developer.id == _vm.auth.id)?_c('div',[(_vm.auth)?_c('div',{staticClass:"wrap-create-btn"},[_c('BaseButton',{attrs:{"class_btn":"btn-green"},on:{"click":_vm.openModal}},[_c('span',[_vm._v(_vm._s(_vm.__('Create match')))])])],1):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('BaseButton',_vm._g(_vm._b({attrs:{"class_btn":"btn-green"},on:{"click":_vm.pushRegister}},'BaseButton',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.__('Create match')))])])]}}],null,false,4099273164)},[_c('span',[_vm._v(_vm._s(_vm.__('You are not logged in')))])])],1):(_vm.game.type_user == 1 || _vm.game.type_user == 3)?_c('div',[(_vm.auth)?_c('div',{staticClass:"wrap-create-btn"},[_c('BaseButton',{attrs:{"class_btn":"btn-green"},on:{"click":_vm.openModal}},[_c('span',[_vm._v(_vm._s(_vm.__('Create match')))])])],1):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('BaseButton',_vm._g(_vm._b({attrs:{"class_btn":"btn-green"},on:{"click":_vm.pushRegister}},'BaseButton',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.__('Create match')))])])]}}])},[_c('span',[_vm._v(_vm._s(_vm.__('You are not logged in')))])])],1):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }