import { render, staticRenderFns } from "./ModalBunMatch.vue?vue&type=template&id=3da08b05&scoped=true&"
import script from "./ModalBunMatch.vue?vue&type=script&lang=js&"
export * from "./ModalBunMatch.vue?vue&type=script&lang=js&"
import style0 from "./ModalBunMatch.vue?vue&type=style&index=0&id=3da08b05&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3da08b05",
  null
  
)

export default component.exports